import { object, string } from 'yup';

import { selectionItemSchema } from '@/schemas';

export const schema = object().shape({
  purchasingType: selectionItemSchema.optional().nullable(),
  estimetedVolumes: string().trim().optional().nullable(),
  // paymentCurrency: selectionItemSchema.required('Payment Currency is Required'),
  measureUnit: selectionItemSchema.required('Measure Unit is Required'),
  // listingFee: bool().optional().nullable(),
  certifications: string().trim().optional().nullable().max(500),
  logisticRequirements: string().trim().optional().nullable().max(500),
  otherInformation: string().trim().optional().nullable().max(500),
});
