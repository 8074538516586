import { Box, Button } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { stepState } from '@/redux/purchasing';
import { setStep } from '@/redux/purchasing/slice';

import { FormType } from '../../types';

import type { IRequestFormProps } from '../../types';

const RequestActionButtons = ({
  index,
  length,
  type,
  onReturn,
}: IRequestFormProps & { onReturn?: () => void }) => {
  const dispatch = useDispatch();
  const activeStep = useSelector(stepState);
  const { t } = useTranslation();

  const handleBack = useCallback(() => {
    onReturn?.();
    dispatch(setStep(activeStep - 1));
  }, [dispatch, activeStep, onReturn]);

  return (
    <Box sx={{ mb: 2 }}>
      <div>
        <Button type="submit" variant="contained" sx={{ mt: 1, mr: 1 }}>
          {index === length - 1 ? t(type == FormType.CREATE ? 'Create' : 'Update') : t('Continue')}
        </Button>
        {index != 0 && (
          <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
            {t('Back')}
          </Button>
        )}
      </div>
    </Box>
  );
};

export default RequestActionButtons;
