import { format } from 'date-fns';

import {
  goodUnitOptions,
  purchasingTypeOptions,
} from '@/components/ui/forms/request/BusinessInfoForm/constants';
import { pricePositionOptions } from '@/components/ui/forms/request/GeneralInformationForm/constants';
import { AwardBrandType } from '@/enums';
import { editorialUtil } from '@/utils';

import { CategoryElement } from '../../lists/components';

import type { IAwardResponse } from '@/dto';
import type { SxProps } from '@mui/material';
import type { ReactElement } from 'react';

interface IListType {
  label: string;
  key: keyof IAwardResponse;
  rowSx?: SxProps;
  format: (v: IAwardResponse) => string | number | Date | ReactElement;
}
export const list = (shouldTrackImage = false): IListType[] => [
  {
    label: 'Close Date',
    key: 'candidaciesCloseAt',
    format: (value) =>
      value?.candidaciesCloseAt ? format(new Date(value.candidaciesCloseAt), 'MM/dd/yyyy') : null,
  },
  {
    label: 'Product Type',
    key: 'productType',
    format: (value) => value.productType,
  },
  {
    label: 'N° Applications',
    key: 'candidacies',
    format: (value) => value?.candidacies?.length,
  },

  {
    label: 'Private Label',
    key: 'brandType',
    format: (value) => (value?.brandType === AwardBrandType.PRIVATE_LABEL ? 'YES' : 'NO'),
  },
  // {
  //   label: 'Currency',
  //   key: 'paymentCurrency',
  //   format: (value) => value.paymentCurrency ?? AwardPaymentCurrency.EUR,
  // },
  {
    label: 'Product Category',
    key: 'categories',
    format: (value) => (
      <CategoryElement
        shouldTrackImage={shouldTrackImage}
        editorial={editorialUtil.parse([value.EditorialSection])}
      />
    ),
    rowSx: { display: 'flex', alignItems: 'center', mb: 1 },
  },
  // {
  //   label: 'Listing Fee',
  //   key: 'listingFee',
  //   format: (value) => (value.listingFee ? 'Yes' : 'No'),
  // },
  {
    label: 'Expected Marketing Investment',
    key: 'marketingInvestmentExpected',
    format: (value) => (value.marketingInvestmentExpected ? 'Yes' : 'No'),
  },
  {
    label: 'Price Position',
    key: 'pricePosition',
    format: (value) => pricePositionOptions.find((v) => v.value == value.pricePosition)?.label,
  },
  {
    label: 'Type of Purchase',
    key: 'purchasingType',
    format: (value) => purchasingTypeOptions.find((v) => v.value == value.purchasingType)?.label,
  },
  {
    label: 'Measure Unit',
    key: 'measureUnit',
    format: (value) => goodUnitOptions.find((v) => v.value == value.measureUnit)?.label,
  },
  {
    label: 'Estimated volumes (SKU)',
    key: 'estimatedVolumes',
    format: (value) => value.estimatedVolumes,
  },
];
