import { bool, mixed, object, string } from 'yup';

import { SendUntilSelect } from './types';

export const schema = object().shape({
  // descriptions: array()
  //   .of(
  //     object().shape({
  //       title: string().trim().required('Questions Is Required').min(1),
  //       helperText: string().trim().optional().nullable(),
  //       // maxLength: number().min(1).required('Please Set Max Answer Length'),
  //       isRequired: bool().required(),
  //     }),
  //   )
  //   .min(0)
  //   .max(10)
  //   .required(),
  targetPrice: string().trim().min(1).max(256).optional().nullable(),
  benchmarkGood: string().trim().min(1).max(256).optional().nullable(),
  marketingIvestmentExpected: bool().optional().nullable(),
  candidaciesCloseAt: mixed()
    .oneOf(Object.values(SendUntilSelect))
    .required('This field is required'),
});
