import convert from 'convert-units';

import { AwardGoodUnitExternal, AwardGoodUnit } from '@/enums';

const platformUnitToString: Record<AwardGoodUnit, string> = {
  [AwardGoodUnit.MILLILITER]: 'ml',
  [AwardGoodUnit.MILLIGRAMS]: 'mg',
  [AwardGoodUnit.GRAMS]: 'g',
};

const unitConversionMap: Record<
  AwardGoodUnitExternal,
  { from: string; to: string; toEnum: AwardGoodUnit }
> = {
  [AwardGoodUnitExternal.FLUID_OUNCE]: {
    from: 'fl-oz',
    to: 'ml',
    toEnum: AwardGoodUnit.MILLILITER,
  },
  [AwardGoodUnitExternal.CUP]: { from: 'cup', to: 'ml', toEnum: AwardGoodUnit.MILLILITER },
  [AwardGoodUnitExternal.PINT]: { from: 'pnt', to: 'ml', toEnum: AwardGoodUnit.MILLILITER },
  [AwardGoodUnitExternal.QUART]: { from: 'qt', to: 'ml', toEnum: AwardGoodUnit.MILLILITER },
  [AwardGoodUnitExternal.GALLON]: { from: 'gal', to: 'ml', toEnum: AwardGoodUnit.MILLILITER },
  [AwardGoodUnitExternal.OUNCE]: { from: 'oz', to: 'g', toEnum: AwardGoodUnit.GRAMS },
  [AwardGoodUnitExternal.POUND]: { from: 'lb', to: 'g', toEnum: AwardGoodUnit.GRAMS },
};

const converUnitEnumToPlatformEnum = (
  unit: AwardGoodUnit | AwardGoodUnitExternal,
): AwardGoodUnit => {
  if (Object.values(AwardGoodUnit).some((item) => item == unit)) {
    return unit as AwardGoodUnit;
  }

  return unitConversionMap[unit].toEnum;
};

const convertUnit = (value: number, unit: AwardGoodUnit | AwardGoodUnitExternal) => {
  if (Object.values(AwardGoodUnit).some((item) => item == unit)) {
    return { value, label: platformUnitToString[unit] };
  }

  const conversion = unitConversionMap[unit];
  return {
    value: Math.round(convert(value).from(conversion.from).to(conversion.to)),
    label: conversion.to,
  };
};

const convertUnitToString = (
  value: number,
  unit: AwardGoodUnit | AwardGoodUnitExternal,
  text?: string,
) => {
  const res = convertUnit(value, unit);

  return res ? `${text} ${res.value.toLocaleString()}${res.label}`.trim() : '';
};

const unitsUtil = {
  convertUnit,
  convertUnitToString,
  unitConversionMap,
  converUnitEnumToPlatformEnum,
};

export default unitsUtil;
