import { useCallback } from 'react';

const useInfoDrawer = () => {
  const onCardClickAction = useCallback(
    (val: string) => () => {
      window.open(val);
    },
    [],
  );

  return { onCardClickAction };
};

export default useInfoDrawer;
