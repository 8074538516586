import { DrawerType } from '@/enums';

import CompanyDataDrawer from './CompanyDataDrawer';
import InfoDrawer from './InfoDrawer';

import type { IDrawerProps } from './types';
import type { FC } from 'react';

export const drawersMap: Record<DrawerType, FC<IDrawerProps>> = {
  [DrawerType.Info]: InfoDrawer,
  [DrawerType.CompanyData]: CompanyDataDrawer,
};

// type GroupedTileInfo<T extends TYPE> = {
//   locationCode: string;
//   customerCode: string;
//   tiles: TileInfo[];
// } & T extends 'Good'
//   ? { customerCode1: string }
//   : {};
