import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';

import { AutocompleteInput, TextInput } from '@/components/ui/inputs';
import { unitsUtil } from '@/utils';

import { RequestActionButtons } from '../components';

import { goodUnitOptions, purchasingTypeOptions } from './constants';
import { schema } from './schema';
import useBusinessInfoForm from './useBusinessInfoForm';

import type { IRequestFormProps } from '../types';

const BusinessInfoForm = (props: IRequestFormProps) => {
  const { initialValues, onSubmit } = useBusinessInfoForm();
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ values }) => (
        <Form>
          <Field
            as={AutocompleteInput}
            label={t('Type of Purchase')}
            name="purchasingType"
            autoCompleteProps={{ options: purchasingTypeOptions }}
          />
          <Field
            as={TextInput}
            label={t('Estimated volumes (SKU)')}
            name="estimetedVolumes"
            required
          />

          <Field
            as={NumericFormat}
            label={t('Product Weight/Volume')}
            name="weight"
            customInput={TextInput}
            allowNegative={false}
            required
            helperText={
              values.measureUnit &&
              values.weight &&
              unitsUtil.convertUnitToString(
                +values.weight,
                values.measureUnit.value,
                'That’s equivalent to',
              )
            }
          />

          <Field
            as={AutocompleteInput}
            label={t('Measure Unit')}
            name="measureUnit"
            helperText="The website uses platform units, but you can choose any unit and we'll convert it for you"
            required
            autoCompleteProps={{ options: goodUnitOptions, groupBy: (option) => option.type }}
          />

          {/* <Field
            as={AutocompleteInput}
            label={t('Payment Currency')}
            name="paymentCurrency"
            required
            autoCompleteProps={{ options: currencyOptions }}
          />

          <Field
            as={SelectInput}
            label={t('Listing Fee')}
            options={boolSelectOptions}
            default={false}
            name="listingFee"
          /> */}

          <Field
            as={TextInput}
            label={t('Certifications needed')}
            helperText="Do you need the product or Company to have specific certifications? If Yes, which ones?"
            name="certifications"
            multiline
            maxRows={5}
            minRows={1}
          />
          <Field
            as={TextInput}
            label={t('Logistic requirements')}
            name="logisticRequirements"
            multiline
            maxRows={5}
            minRows={1}
          />
          <Field
            as={TextInput}
            label={t('Other information')}
            name="otherInformation"
            multiline
            maxRows={5}
            minRows={1}
          />

          <RequestActionButtons {...props} />
        </Form>
      )}
    </Formik>
  );
};

export default BusinessInfoForm;
