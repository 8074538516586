import { createSlice } from '@reduxjs/toolkit';

import type IPurchasingState from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IPurchasingState = {
  step: 0,
  id: null,
  generalInfo: null,
  businessOrientedInfo: null,
  priceExpectationsAndInvestments: null,
};

export const purchasingSlice = createSlice({
  name: 'purchasing',
  initialState,
  reducers: {
    resetPurchasingState: () => initialState,
    setGeneralInfo(state, { payload }) {
      state.generalInfo = payload;
    },
    setBusinessOrientedInfo(state, { payload }) {
      state.businessOrientedInfo = payload;
    },
    setPriceExpectationsAndInvestments(state, { payload }) {
      state.priceExpectationsAndInvestments = payload;
    },
    setStep(state, { payload }) {
      state.step = payload;
    },
    setEditInitData: (_, { payload }: PayloadAction<IPurchasingState>) => payload,
  },
});

export const {
  resetPurchasingState,
  setGeneralInfo,
  setBusinessOrientedInfo,
  setStep,
  setEditInitData,
  setPriceExpectationsAndInvestments,
} = purchasingSlice.actions;

export default purchasingSlice.reducer;
