import { getUserData } from '@food/auth';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

import { awardSelectedState } from '@/redux/awards/selectors';
import { requestState } from '@/redux/purchasing';
import { setPriceExpectationsAndInvestments } from '@/redux/purchasing/slice';
import { createAwards, updateAwards } from '@/redux/purchasing/thunks';
import { usersRetailerState } from '@/redux/user/selectors';
import { dateUtil, unitsUtil } from '@/utils';

import { defaultValues, emptyItem } from './constants';

import type { IPriceExpectationsAndInvestmentsForm } from '@/redux/purchasing';
import type { AppDispatch } from '@/redux/store';

const useDescriptionForm = () => {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const { priceExpectationsAndInvestments, businessOrientedInfo, generalInfo, id } =
    useSelector(requestState);
  const { selected } = useSelector(awardSelectedState);

  const initialValues: IPriceExpectationsAndInvestmentsForm = useMemo(
    () =>
      priceExpectationsAndInvestments || {
        // descriptions: [emptyItem],
        targetPrice: '',
        marketingIvestmentExpected: false,
        candidaciesCloseAt: null,
        benchmarkGood: '',
      },
    [priceExpectationsAndInvestments],
  );

  const addNewItem = useCallback((setFucntion, value) => {
    setFucntion('descriptions', [...value, emptyItem]);
  }, []);

  const removeItem = useCallback((setFucntion, value, index) => {
    const newValue = value.filter((_, ind) => ind !== index);
    setFucntion('descriptions', newValue);
  }, []);

  const retailerData = useSelector(usersRetailerState);

  const onReturn = useCallback(
    (value: IPriceExpectationsAndInvestmentsForm) => {
      dispatch(setPriceExpectationsAndInvestments(value));
    },
    [dispatch],
  );

  const onSubmit = useCallback(
    async (value: IPriceExpectationsAndInvestmentsForm) => {
      const user = getUserData();
      const measureUnit = unitsUtil.converUnitEnumToPlatformEnum(
        businessOrientedInfo.measureUnit.value,
      );

      const parsedWeight = unitsUtil.convertUnit(
        +businessOrientedInfo.weight,
        businessOrientedInfo.measureUnit.value,
      );
      console.log(parsedWeight);

      const data = {
        name: `${retailerData.Country.name} Retailer - ${generalInfo.productType} - ${new Date().getFullYear()}`,
        productType: generalInfo.productType,
        EditorialSection: {
          id: generalInfo.editorySectionId.value,
        },
        benchmarkGood: value.benchmarkGood,
        pricePosition: generalInfo.pricePosition.value,

        brandType: generalInfo.brandType.value,
        purchasingType: businessOrientedInfo.purchasingType?.value,
        // paymentCurrency: businessOrientedInfo.paymentCurrency.value,
        measureUnit,
        estimatedVolumes: businessOrientedInfo.estimetedVolumes,
        // listingFee: businessOrientedInfo.listingFee,
        marketingInvestmentExpected: value.marketingIvestmentExpected,
        candidaciesCloseAt: dateUtil.getDateFromSendUntilEnum(
          value.candidaciesCloseAt,
          selected?.candidaciesCloseAt,
        ),
        ...defaultValues,
        // ...value.descriptions
        // .map((item, ind) => ({
        //   [`description${ind + 1}Label`]: `${item.title}${item.isRequired ? '*|' : '|'}${item.helperText}`,
        //   // [`description${ind + 1}Max`]: item.maxLength,
        // }))
        // .reduce((a, b) => ({ ...a, ...b }), {}),

        //TODO
        description10Label: `Certifications: ${businessOrientedInfo.certifications}`,
        description9Label: `Logistic Requirements: ${businessOrientedInfo.logisticRequirements}`,
        description8Label: `Other Information: ${businessOrientedInfo.otherInformation}`,
        description7Label: `Weight/Volume: ${parsedWeight.value} ${parsedWeight.label}`,
        description6Label: `Target Price: ${value.targetPrice}`,
      };
      console.log('here');

      const promise = id
        ? dispatch(updateAwards({ id, values: data }))
        : dispatch(createAwards({ values: { ...data, managerUser: { id: user.id } } }));

      await promise
        .unwrap()
        .then((res) => {
          toast.success('Success');
          history.push('/purchasing-request/' + res.data.entity.id);
        })
        .catch(() => toast.error('Something went wrong'));
    },
    [
      generalInfo,
      businessOrientedInfo,
      retailerData,
      dispatch,
      history,
      id,
      selected?.candidaciesCloseAt,
    ],
  );

  return { initialValues, onSubmit, addNewItem, onReturn, removeItem };
};

export default useDescriptionForm;
