import NewIdeasImage from '@/assets/images/new-ideas.jpg';
import NewIdeasWideImage from '@/assets/images/new-ideas_wide.png';
import PrivateLabelImage from '@/assets/images/private-label.jpg';
import PrivateLabelWideImage from '@/assets/images/private-label_wide.jpg';
import ProfitImage from '@/assets/images/profit.jpg';
import ProfitWideImage from '@/assets/images/profit_wide.jpg';
import { CardSize } from '@/enums/cards';

import type { ISubPageCardProps } from '@/components/ui/cards/SubPageCard/types';
import type { TFunction } from 'i18next';

export const infoCards = (
  t: TFunction,
  onClick: (val: string) => () => void,
  variant = CardSize.Small,
): ISubPageCardProps[] => [
  {
    variant,
    title: t('New Ideas'),
    subTitle: t('New Ideas description'),
    onClick: onClick('https://www.italianfoodsourcing.com/for-buyers/products/'),
    image: variant == CardSize.Small ? NewIdeasWideImage : NewIdeasImage,
  },
  {
    variant,
    title: t('Trendings'),
    subTitle: t('Trendings description'),
    onClick: onClick('https://www.italianfoodsourcing.com/for-buyers/marketing-insights-new/'),
    image: variant == CardSize.Small ? ProfitWideImage : ProfitImage,
  },
  {
    variant,
    title: t('Private Label'),
    subTitle: t('Private Label description'),
    onClick: onClick('https://www.italianfoodsourcing.com/italian-private-label/'),
    image: variant == CardSize.Small ? PrivateLabelWideImage : PrivateLabelImage,
  },
];
